(function () {
  if (window.active_modules.indexOf("kraken") === -1) {
    return;
  }

  window.playerManager = window.playerManager || [];
  let isIntersecting;

  const handleMessageEvent = (event) => {
    if (event.data.isIntersecting === true) {
      window.removeEventListener("message", handleMessageEvent);
      if (window.playerManager.initialized) {
        window.playerManager.push((instance) => {
          instance.getPlayer(0).theo.muted = true;
          instance.getPlayer(0).theo.play();
        });
      } else {
        isIntersecting = true;
      }
    }
  };

  window.addEventListener("message", handleMessageEvent);

  fetch("https://www.expressen.se/video-player/api/bundle").then((response) => response.json()).then((data) => {
    const divType = document.querySelector("div[data-type='qbrick']").getAttribute("data-type");
    if (divType !== "qbrick") return;

    const adUnitsMap = { "viivilla.se": "/34405621/bn/vi-i-villa-se" };
    const resourceContainer = document.querySelector(".resource-container");
    const apiUrl = document.querySelector("div[data-api-url]").getAttribute("data-api-url");
    const playlistId = "07528c63-04eb-43cf-af21-69e0cbaa2d4a";
    const externalPlaylistBaseUrl = "https://video.qbrick.com/api/v1/public/accounts/AccDeRlL2kxhkOGiOE6xxtRAQ/playlists/";
    if (!resourceContainer || !apiUrl) return;

    const domain = document.referrer ? new URL(document.referrer).hostname : window.location.hostname;
    const fetchData = async () => {
      const response = await (await fetch(apiUrl)).json();
      const stream = response.asset.resources.find((resource) => resource.type === "index");
      const video = response.asset.resources.find((asset) => asset.type === "video");
      const videoRendition = video.renditions.find((r) => r.type === "video");
      const videoLink = videoRendition.links.find((l) => l.mimeType === "video/mp4");
      const videoDuration = videoRendition.videos.find((d) => d.duration);
      const rendition = stream.renditions.find((r) => r.type === "index");
      const link = rendition.links.find((l) => l.href.endsWith(".m3u8"));
      const created = new Date(response.created);
      const duration = Math.round(videoDuration.duration);
      const thumbnailId = response.thumbnails.find((t) => t.id).id;
      const thumbnailRenditions = response.asset.resources.find((r) => r.id === thumbnailId).renditions.sort((a, b) => a.size - b.size);
      const thumbnail = thumbnailRenditions[0].links.find((l) => l.href);

      return {
        articleData: {
          title: response.metadata.title,
          stream: link.href,
          mp4File: videoLink.href,
          image: thumbnail.href,
          totalSecondsDuration: duration,
          playlistId,
          externalPlaylistBaseUrl,
          playlistRecommendations: true,
        },
        adConfig: { adUnit: adUnitsMap[domain] },
        videoTrackingInfo: {
          contentId: response.asset.id,
          domain,
          title: response.metadata.title,
          videoTitle: response.metadata.title,
          videoId: video.id,
          videoType: "video",
          videoTotalSecondsDuration: duration,
          titleRaw: response.metadata.title,
          published: response.created,
          publishDateDatepartSweden: `${created.getFullYear()}-${created.getMonth() + 1}-${created.getDate()}`,
          publishDateTimepartSweden: `${created.getHours()}:${created.getMinutes()}:${created.getSeconds()}`,
          sections: [],
        },
      };
    };
    const div = document.createElement("div");
    div.className = "evp";
    resourceContainer.appendChild(div);

    const head = document.getElementsByTagName("head")[0];

    const css = `https://www.expressen.se${data.css[0]}`;
    const style = document.createElement("link");
    const styles = `@import url(${css});`;
    style.rel = "stylesheet";
    style.href = `data:text/css,${styles}`;

    const js = data.js[0];
    const script = document.createElement("script");
    script.src = `https://www.expressen.se${js}`;

    head.appendChild(script);
    head.appendChild(style);

    window.playerManager.push(async (instance) => {
      const options = {
        className: "evp",
        useCdn: true,
        fetchData,
        cmpEnabled: false,
        useInternalEmbed: true,
        partnerId: "resource-service",
      };
      await instance.init(options);
      if (isIntersecting) {
        instance.getPlayer(0).theo.muted = true;
        instance.getPlayer(0).theo.play();
      }
    });
  });
})();
